import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import authReducer from "./authReducer";
import LoginAction from "../actions/authActions";

const rootReducer = combineReducers({
  themeReducer,
  authReducer,
});
export default (state, action) =>
  rootReducer(action.type == "GET_LOGOUT" ? undefined : state, action);
